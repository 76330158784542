<template>
  <v-card max-width="400" class="mx-auto">
    <!--  company's logo -->
    <v-img :src="imageUrl" height="200px"></v-img>

    <!--  list company, disclaimer, contact, ... -->
    <v-list two-line>
      <!-- company Name & Description -->
      <v-list-item>
        <v-list-item-action></v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{ companyName }}</v-list-item-title>
          <v-list-item-subtitle>{{ companyDescription }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider inset></v-divider>

      <!-- company disclaimer -->
      <v-list-item>
        <v-list-item-icon>
          <v-icon :color="menuItemColorClass">
            {{ iconWarning }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ disclaimer }}</v-list-item-title>
          <v-list-item-subtitle>{{
            disclaimerDescription
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider inset></v-divider>

      <!-- Contact mobile-Phone number -->
      <v-list-item>
        <v-list-item-icon>
          <v-icon :color="menuItemColorClass">
            {{ iconPhoneWork }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ contactPhone }}</v-list-item-title>
          <v-list-item-subtitle>{{ contactPhoneType }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon>{{ iconMessageText }}</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider inset></v-divider>

      <!-- Contact email -->
      <v-list-item>
        <v-list-item-icon>
          <v-icon :color="menuItemColorClass">
            {{ iconEmail }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ contactEmail }}</v-list-item-title>
          <v-list-item-subtitle>{{ contactEmailType }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider inset></v-divider>

      <!-- Contact address -->
      <v-list-item>
        <v-list-item-icon>
          <v-icon :color="menuItemColorClass">
            {{ iconMapMarker }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ addressLine1 }}</v-list-item-title>
          <v-list-item-subtitle>{{ addressCityStateZip }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
// design
import {
  iconMessageText,
  iconPhoneWork,
  iconEmail,
  iconMapMarker,
  iconWarning
} from "@/design/icon/iconConst";

// mixins
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";

export default {
  name: "AboutPublicAccess",
  mixins: [publicAccessMixin, menuItemSettingsMixin],
  data() {
    return {
      iconPhoneWork: iconPhoneWork,
      iconMessageText: iconMessageText,
      iconEmail: iconEmail,
      iconMapMarker: iconMapMarker,
      iconWarning: iconWarning
    };
  },
  computed: {
    imageUrl() {
      return (
        this.publicAccess?.company?.logo ??
        this.publicAccess?.appBar?.backgroundImageUrl
      );
    },
    companyName() {
      return this.publicAccess?.company?.name;
    },
    companyDescription() {
      return this.publicAccess?.company?.description;
    },
    disclaimer() {
      return "Disclaimer";
    },
    disclaimerDescription() {
      return this.publicAccess?.company?.disclaimers;
    },
    addressLine1() {
      return this.publicAccess?.company?.address?.addressLine1;
    },
    addressCityStateZip() {
      return `${this.publicAccess?.company?.address?.city}, ${this.publicAccess?.company?.address?.state} ${this.publicAccess?.company?.address?.zip}`;
    },
    contactPhone() {
      return this.publicAccess?.contact?.phone;
    },
    contactPhoneType() {
      return this.publicAccess?.contact?.phoneDescription;
    },
    contactEmail() {
      return this.publicAccess?.contact?.email;
    },
    contactEmailType() {
      return this.publicAccess?.contact?.emailDescription;
    }
  }
};
</script>
